.indicator {
  @include transition(opacity 0.5s ease, visibility 0.5s ease);
  position: fixed;
  z-index: 400;
  bottom: 30px;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  // box-shadow: inset 0 0 0 0.1rem rgba(128, 130, 134, 1);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  @include flipProperty('right', 'left', 33px);
  @include mdq(all-mobile) {
    @include flipProperty('right', 'left', 15px);
    width: 40px;
    height: 40px;
  }
  @include mdq(all-desktop) {
    width: 3.75vw;
    height: 3.75vw;
    @include flipProperty('right', 'left', 1.71875vw);
  }

  &.is-show {
    opacity: 1;
    visibility: visible;
  }

  &__circle {
    //    position: relative;
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      path {
        box-sizing: border-box;
        fill: transparent;
        stroke: var(--color-orange);
        stroke-width: 4;
        transition: all 200ms linear;
      }
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    //top: calc(50% - 20px);
    svg {
      width: 23px;
      //      height: 23px;
      @include mdq(all-desktop) {
        width: 1.197916vw;
        height: 1.197916vw;
      }

      path {
        fill: transparent;
        stroke: var(--color-orange);
      }
    }
  }
}
