.strategy-video__slider {
  overflow: auto;
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .mySwiper2 {
    overflow: visible !important;
    height: 32.813vw;
    @include mdq(all-mobile) {
      height: 40vh;
    }
    width: 100%;
  }

  .mySwiper {
    height: 5.208vw;
    @include mdq(all-mobile) {
      height: 6.25vh;
    }
    width: 30%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /*   .swiper-button-next {
    right: -0.5vw !important;
  }
  .swiper-button-prev {
    right: 3vw !important;
  } */
  /*   .swiper-button-next,
  .swiper-button-prev {
    bottom: -10%;
  } */
  .swiper-count {
    position: absolute;
    @extend .font-size-40;
    @extend .raleway-regular;
    color: CBCBCB;
  }
  .swiper-count .active-count {
    color: #00a6aa;
  }
}
.read-more-btn {
  svg {
    [dir='rtl'] & {
      transform: rotateY(180deg);
    }
  }
}
// #s4-workspace h1,
// #s4-workspace h2,
// #s4-workspace h3,
// #s4-workspace h4,
// #s4-workspace h5 {
//   @extend .raleway-semibold;
// }
// #s4-workspace,
// #s4-workspace main p {
//   @extend .raleway-regular;
// }
.read-more-btn {
  [dir='rtl'] & {
    align-items: baseline;
  }
}
#s4-workspace .about-card h2 {
  @extend .raleway-medium;
}
.sub-more-item {
  .arrow {
    [dir='rtl'] & {
      transform: rotateY(180deg);
    }
  }
}
.filped-ar {
  [dir='rtl'] & {
    transform: scale(-1);
  }
}
