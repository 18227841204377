.sub-header {
  .before-1 {
    background: linear-gradient(180deg, rgba(25, 28, 31, 0) 0%, #191c1f 100%);
  }
  .header-text {
    h1 {
      @extend .font-size-68;
    }
  }
}
.desc-p {
  p {
    &,
    &:last-child {
      margin: 0 !important;
    }
    &:not(:last-child) {
      @include mdq(all-mobile) {
        margin-bottom: 15px;
      }
      @include mdq(all-tablet) {
        margin-bottom: 25px;
      }
      @include mdq(all-desktop) {
        @include propInVW(margin-bottom, 40);
      }
    }
  }
}
.pillar-item {
  .before {
    opacity: 0.9;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }
}
.more-item {
  .before {
    background: linear-gradient(180deg, rgba(25, 28, 31, 0) 0%, #191c1f 100%);
  }
}

.read-more-btn {
  &,
  svg,
  span {
    @extend .transition-speed;
  }
  &:hover {
    span {
      color: #00a6aa !important;
    }
    svg {
      transform: translateX(8px);
      path {
        fill: #00a6aa;
      }
    }
  }
}
.pillar-item {
  &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.4s ease-in-out;
    }
  }
}
.sub-more-item {
  &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.4s ease-in-out;
    }
  }
}
.more-item {
  &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.4s ease-in-out;
    }
  }
}
[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0);
  @include mdq(all-mobile) {
    transform: translate3d(0, 30px, 0);
  }
}
[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0);
  @include mdq(all-mobile) {
    transform: translate3d(0, 30px, 0);
  }
}
