.info-cards {
  .card {
    &.v1 {
      &.active {
        .line {
          transition: all 5s ease-in-out;
          [dir='ltr'] & {
            @include mdq(all-mobile) {
              transform: translateX(40px);
            }
            transform: translateX(300px);
          }
          [dir='rtl'] & {
            @include mdq(all-mobile) {
              transform: translateX(-40px);
            }
            transform: translateX(-300px);
          }
        }
      }
    }
    &.v2 {
      &.active {
        .line {
          transition: all 5s ease-in-out;
          [dir='rtl'] & {
            @include mdq(all-mobile) {
              transform: translateX(40px);
            }
            transform: translateX(300px);
          }
          [dir='ltr'] & {
            @include mdq(all-mobile) {
              transform: translateX(-40px);
            }
            transform: translateX(-300px);
          }
        }
      }
    }
  }
}

.slider-cards {
  img {
    transition: all 0.5s ease-in-out;
  }
}
#subFuture {
  .info-cards {
    .cont {
      @include mdq(all-mobile) {
        font-size: 14px !important;
        line-height: 24px !important;
      }
    }
  }
}
