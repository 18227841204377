.strategy-piilar-item {
  .image-before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  }
}

.fancybox-button--close {
  width: 56px;
  height: 56px;
  background-image: url('../img/icons/close-circle.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  @include mdq(all-mobile) {
    width: 35px;
    height: 35px;
  }
  @include mdq(all-desktop) {
    @include propInVW('width', 56);
    @include propInVW('height', 56);
  }

  svg {
    display: none;
  }
}
.lottie-play {
  @include mdq(all-mobile) {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
  }
}
.strategy-video__slider .main-container {
  @include mdq(all-tablet) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.strategy-piilar-item {
  &:hover {
    img {
      transform: scale(1.1);
      transition: all 0.4s ease-in-out;
    }
  }
}
.mySwiper2 {
  .swiper-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0) !important;
    //overflow: hidden !important;
  }
}
