$color-default-white: #ffffff;
$color-default-black: #000;
$color-math-black: #212B36;
$color-math-black2: #232221;
$color-math-black3: #191c1f;
$color-dark-blue: #002257;
$color-dark-blue-2: #1d51a4;
$color-light-blue: #3db3e3;
$color-light-blue2: #07999d;
$color-light-blue3: #668cc8;
$color-light-gray: #ececec;
$color-light-yellow: #eaab00;
$color-orange: #ee7203;
$color-light-font: #cbcbcb;
$color-light-font2: #26292c;
$color-font: #191c1f;
$color-green: #00764a;
$color-green2: #05D487;
$color-grey: #F4F5F7;
$color-grey2: #f6f6f6;
$color-purple: #67136E;
$color-sky-blue: #0074e8;
$color-red: #EA3323;
$color-pink: #992861;
:root {
  --color-default-white: #{$color-default-white};
  --color-default-black: #{$color-default-black};
  --color-math-black: #{$color-math-black};
  --color-math-black2: #{$color-math-black2};
  --color-math-black3: #{$color-math-black3};
  --color-dark-blue: #{$color-dark-blue};
  --color-dark-blue-2: #{$color-dark-blue-2};
  --color-light-blue: #{$color-light-blue};
  --color-light-blue2: #{$color-light-blue2};
  --color-light-blue3: #{$color-light-blue3};
  --color-light-gray: #{$color-light-gray};
  --color-orange: #{$color-orange};
  --color-light-yellow: #{$color-light-yellow};
  --color-light-font: #{$color-light-font};
  --color-light-font2: #{$color-light-font2};
  --color-font: #{$color-font};
  --color-green: #{$color-green};
  --color-green2: #{$color-green2};
  --color-grey: #{$color-grey};
  --color-grey2: #{$color-grey2};
  --color-purple: #{$color-purple};
  --color-sky-blue: #{$color-sky-blue};
  --color-red: #{$color-red};
  --color-pink: #{$color-pink};
}
