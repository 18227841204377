@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Helvetica Bold';
  src: url('../fonts/Helvetica/HelveticaNeueLTArabic-Bold.eot');
  src: url('../fonts/Helvetica/HelveticaNeueLTArabic-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Bold.woff2') format('woff2'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Bold.woff') format('woff'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Bold.ttf') format('truetype'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Bold.svg#HelveticaNeueLTArabic-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica 55';
  src: url('../fonts/Helvetica/HelveticaNeueLTArabic-Roman.eot');
  src: url('../fonts/Helvetica/HelveticaNeueLTArabic-Roman.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Roman.woff2') format('woff2'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Roman.woff') format('woff'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Roman.ttf') format('truetype'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Roman.svg#HelveticaNeueLTArabic-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Light';
  src: url('../fonts/Helvetica/HelveticaNeueLTArabic-Light.eot');
  src: url('../fonts/Helvetica/HelveticaNeueLTArabic-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Light.woff2') format('woff2'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Light.woff') format('woff'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Light.ttf') format('truetype'),
    url('../fonts/Helvetica/HelveticaNeueLTArabic-Light.svg#HelveticaNeueLTArabic-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.raleway-light {
  font-family: 'Raleway', sans-serif !important;
  [dir='ltr'] & {
    font-weight: 300 !important;
    font-family: 'Raleway', sans-serif !important;
  }

  [dir='rtl'] & {
    font-weight: 300 !important;
    font-family: 'Helvetica Neue LT Arabic' !important;
  }
}

.raleway-regular {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 400 !important;
  [dir='rtl'] & {
    font-family: 'Helvetica Neue LT Arabic' !important;
  }
}
.raleway-medium {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 500 !important;
  [dir='rtl'] & {
    font-family: 'Helvetica Neue LT Arabic' !important;
  }
}
.raleway-semibold {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 600 !important;
  [dir='rtl'] & {
    font-family: 'Helvetica Neue LT Arabic' !important;
  }
}
.raleway-bold {
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700 !important;
  [dir='rtl'] & {
    font-family: 'Helvetica Neue LT Arabic' !important;
  }
}
p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  [dir='rtl'] & {
    font-family: 'Helvetica Neue LT Arabic' !important;
  }
}
