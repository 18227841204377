.loader {
  @extend .bg-math-black;
  @include transition(opacity 0.5s ease, visibility 0.5s ease);
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  .dots span {
    position: absolute;
    height: 10px;
    width: 10px;
    background: var(--color-orange);
    border-radius: 50%;
    transform: rotate(calc(var(--i) * (360deg / 15))) translateY(35px);
    animation: animate 1.5s linear infinite;
    animation-delay: calc(var(--i) * 0.1s);
    opacity: 0;
  }

  &.hide {
    animation-duration: 0.5s;
  }
}
@keyframes animate {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
