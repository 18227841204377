@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'config/mixins';
@import 'config/colors';
@import 'config/fonts';
@import 'config/base';
@import 'config/config';
@import 'vendors/**.scss';
@import 'helpers/**.scss';
@import 'components/**.scss';
@import 'partials/**.scss';
@import 'pages/**.scss';

@media only screen and (min-width: 1023px) {
  .grid-col-7 {
    grid-column: span 7 / span 7;
  }
  .grid-col-5 {
    grid-column: span 5 / span 5;
  }
}
header *:not(.tools) {
  box-sizing: content-box !important;
}
