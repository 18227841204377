.parallax {
  &__img {
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    [dir='rtl'] & {
      transform: scaleX(-1);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
